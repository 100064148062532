import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useCookies } from "react-cookie";
import { trackDonate } from "../../app/tracking";

import "./Home.css";

export function WelcomeModal() {
  const [cookies, setCookie] = useCookies(["welcomePopState"]);

  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(cookies.welcomePopState !== "shown");
    setCookie("welcomePopState", "shown", { path: "/" });
  }, [setCookie, setShow, cookies]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const onDonate = (event: React.MouseEvent<HTMLElement>) => {
    event.currentTarget.blur();
    window.open("https://cash.app/$Postsecretvoicemail", "_blank");
    trackDonate("welcomeModal");
  };

  return (
    <>
      <Button style={{ marginBottom: 16 }} variant="outline-secondary" onClick={handleShow}>
        More info
      </Button>

      <Modal show={show} size="lg" centered onHide={handleClose} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Welcome to PSVM!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="welcome-popup-section-title">TL;DR</div>
          <div className="welcome-popup-section-body">
            Call our phone number to leave an anonymous voice recording or click on a cassette
            to play someone else's.{" "}
          </div>

          <hr />

          <div className="welcome-popup-section-title">What is PSVM?</div>
          <div className="welcome-popup-section-body">
            Post Secret Voicemail (PSVM) is a platform where people can anonymously share any
            message that they want with the world literally in their own words, and voice. All
            social platforms today, Facebook, Instagram, and others are based on visual
            interaction. You see a video, read someones comment etc. We wanted to humanize
            interactions instead of just being another profile on a page. We believe you're able
            to connect more to someone by leaving everything else out and just, listening. By
            adding in total anonymity, it allows us to be more honest and open and say things we
            might not usually say if people knew who we were. When you hear someone's words in
            their own voice, with no visual bias (no profile or picture to attach them to) you
            only know them by their message, by their words. This project was inspired by many
            years of enjoying <a href="https://postsecret.com">PostSecret</a>. However, we are
            not affiliated in any way.
            <hr />
            <b>
              PSVM may also provide a way to get a message to the outside world for those of us
              living in countries that do not allow us to speak freely.
            </b>
          </div>

          <hr />

          <div className="welcome-popup-section-title">Guidelines</div>
          <div className="welcome-popup-section-body">
            Please avoid the use of:
            <ul className="pl-3">
              <li>
                Personal identifiable information. You may identify yourself by an alias, say
                how old your are, and/or provide a general location if you wish. Ex: "I'm 30
                years old from California..."
              </li>
              <li>Violence promoting content</li>
              <li>Misinformation</li>
              <li>Hate speech</li>
            </ul>
          </div>

          <hr />

          <div className="welcome-popup-section-title">How it works</div>
          <div className="welcome-popup-section-body">
            <ol className="pl-3">
              <li>You call a number and leave your message (voicemail).</li>
              <li>
                The anonymous messages are then available to listen to on
                postsecretvoicemail.com
              </li>
              <li>There is no signup or login so it's "no strings attached".</li>
              <li className="font-weight-bold">
                We collect no data regarding the phone number ensuring you total anonymity. All
                phone numbers are irreversibly encrypted so we do not know who called or from
                what number.
              </li>
              <li>
                There are very basic guidelines for what you can leave in your message, we just
                ask that whatever you say is true and honest.
              </li>
              <li>Explore, search, and enjoy the other message on the site!</li>
            </ol>
          </div>
          <hr />
          <div className="welcome-popup-section-title">Community</div>
          <div className="welcome-popup-section-body">
            <ul className="pl-3">
              <li>
                Reddit -
                <a
                  href="https://www.reddit.com/r/PostSecretVoicemail/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Join r/PostSecretVoicemail
                </a>
              </li>
            </ul>
          </div>
          <hr />
          <div className="welcome-popup-section-title">Donate</div>
          <div className="welcome-popup-section-body">
            Donations are greatly appreciated and will help us keep PSVM up :)
            <Button style={{ fontSize: "small" }} variant="link" onClick={onDonate}>
              Donate
            </Button>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Okay
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

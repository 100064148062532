import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { fetchStatsAsync } from "./API";
import { Stats } from "./types";

export interface StatsState {
  data: Stats;
  status: "idle" | "loading" | "failed";
}

const initialState: StatsState = {
  data: {
    listens: 0,
  },
  status: "idle",
};

export const statsSlice = createSlice({
  name: "data/stats",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchStatsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchStatsAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.data = action.payload;
      });
  },
});

// Actions

// export const {} = statsSlice.actions;

// Selectors

export const selectListens = (state: RootState) => state.stats.data.listens;

// Export the reducer

export default statsSlice.reducer;

import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { Line, Prompt, PromptDetail, Recording, RecordingDetail, Stats } from "./types";
import { getCookie } from "../../app/helpers";

export interface PaginatedResponse<T> {
  count: number;
  next: string | null;
  previous: string | null;
  results: T[];
}

export interface GetRecordingsRequest {
  page?: number;
  pageSize?: number;
  search?: string;
  prompt?: number;
  caller?: number;
}

async function fetchRecordings(params: GetRecordingsRequest) {
  if (params.search === "") {
    params.search = undefined;
  }
  var response = await axios.get<PaginatedResponse<Recording>>("/api/recordings", {
    params,
  });
  return response.data;
}

async function fetchRecording(id: number) {
  var response = await axios.get<RecordingDetail>(`/api/recordings/${id}`);
  return response.data;
}

async function fetchLines() {
  var response = await axios.get<Line[]>("/api/lines");
  return response.data;
}

async function fetchPrompts() {
  var response = await axios.get<PaginatedResponse<Prompt>>("/api/prompts");
  return response.data;
}

async function fetchPrompt(id: number) {
  var response = await axios.get<PromptDetail>(`/api/prompts/${id}`);
  return response.data;
}

async function fetchStats() {
  var response = await axios.get<Stats>("/api/stats");
  return response.data;
}

export enum InappropriateReason {
  hateSpeech = "hate-speech",
  promotesViolence = "promotes-violence",
  misinformation = "misinformation",
  containsPii = "contains-pii",
  phishing = "phishing",
  spam = "spam",
  scam = "scam",
  other = "other",
}

export interface PostRecordingReportRequest {
  recording: number;
  reasons: string[];
}

export async function postReportRecording(request: PostRecordingReportRequest) {
  var response = await axios.post("/api/recordings/report", request);
  return response.status === 200;
}

export async function postPlayCounterIncrement(recordingId: number) {
  var response = await axios.post(`/api/recordings/${recordingId}/played`);
  return response.status === 200;
}

// Async Thunks Wrap API Calls

export const fetchRecordingsAsync = createAsyncThunk(
  "data/fetchRecordings",
  async (params: GetRecordingsRequest) => await fetchRecordings(params)
);

export const fetchRecordingAsync = createAsyncThunk(
  "data/fetchRecording",
  async (id: number) => await fetchRecording(id)
);

export const fetchLinesAsync = createAsyncThunk(
  "data/fetchLines",
  async () => await fetchLines()
);

export const fetchPromptsAsync = createAsyncThunk(
  "data/fetchPrompts",
  async () => await fetchPrompts()
);

export const fetchPromptAsync = createAsyncThunk(
  "data/fetchPrompt",
  async (id: number) => await fetchPrompt(id)
);

export const fetchStatsAsync = createAsyncThunk(
  "data/fetchStats",
  async () => await fetchStats()
);

// Interceptors

axios.interceptors.request.use((config) => {
  config.headers = config.headers || {};

  // Add the Cross-Site Request Forgery token required by Django
  const csfttoken = getCookie("csrftoken");
  if (csfttoken) {
    config.headers["X-CSRFToken"] = csfttoken;
  }

  console.debug(
    `${config.method?.toUpperCase()} ${config.url} ${JSON.stringify(config.params) ?? ""}`
  );
  return config;
});

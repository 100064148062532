import { useEffect, useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import Moment from "moment";
import copy from "copy-to-clipboard";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Recording } from "../Data/types";
import {
  pause,
  play,
  selectIsLoading,
  selectIsPlaying,
  upsertSource,
} from "../AudioEngine/audioEngineSlice";
import { postReportRecording } from "../Data/API";
import tape from "./tape.png";
import tapePlaying from "./tape-playing-forward.gif";
import { ReportForm } from "./ReportForm";
import "./Recordings.css";
import { trackShare } from "../../app/tracking";

export interface Props {
  recording: Recording;
  hidePromptLink: boolean;
}

export function RecordingListItem(props: Props) {
  const { recording, hidePromptLink = false } = props;

  const dispatch = useAppDispatch();
  const isPlaying = useAppSelector(selectIsPlaying(recording.id));
  const isLoading = useAppSelector(selectIsLoading(recording.id));
  const [formOpen, setFormOpen] = useState(false);
  const imgSrc = isPlaying ? tapePlaying : tape;

  useEffect(() => {
    dispatch(
      upsertSource({
        id: recording.id,
        url: recording.mp3Url,
        seconds: recording.seconds,
      })
    );
  }, [recording, dispatch]);

  const onSubmit = (reasons: string[]) => {
    postReportRecording({ recording: recording.id, reasons: reasons }).then(() =>
      setFormOpen(false)
    );
  };

  const onReport = (event: React.MouseEvent<HTMLElement>) => {
    event.currentTarget.blur();
    setFormOpen(!formOpen);
  };

  const onToggle = () => {
    if (isLoading) {
      return;
    } else if (isPlaying) {
      dispatch(pause(recording.id));
    } else {
      dispatch(play(recording.id));
    }
  };

  const onShare = async (event: React.MouseEvent<HTMLElement>) => {
    event.currentTarget.blur();
    const link = `${window.origin}/share/${recording.id}`;
    copy(link, { message: `PSVM msg #{recording.id}` });
    alert("Link copied to clipboard");

    // Google analytics tracking of share feature click
    trackShare(recording.id);
  };

  const formatDateTime = (timestamp: string) => {
    return Moment(timestamp).format("MMM Do YYYY, h:mm a");
  };

  return (
    <div className="cassette-container">
      {isLoading && (
        <div>
          <Spinner className="cassette-loading" animation="grow" />
        </div>
      )}
      <canvas
        className={isPlaying ? "freqviz freqviz-playing" : "freqviz"}
        id={`freqviz-${recording.id}`}
      ></canvas>
      <div className="cassette-label-recording scale-text-with-cassette">
        msg #{recording.id}
      </div>
      <div className="cassette-label-desc scale-text-with-cassette">
        {recording.description}
      </div>
      <div className="cassette-label-created scale-text-with-cassette">
        {formatDateTime(recording.created)}
      </div>

      <img className="cassette-image" src={imgSrc} onClick={() => onToggle()} alt="" />

      <div className="cassette-actions">
      {recording.promptId && !hidePromptLink && <Button
          href={`/p/${recording.promptId}`}
          className="action-link"
          type="button"
          variant="link"
          rel="noreferrer"
          target="_blank"
        >
          View Prompt
        </Button>}
        <Button className="action-link" type="button" variant="link" onClick={onShare}>
          Share
        </Button>
        <Button className="action-link" type="button" variant="link" onClick={onReport}>
          Report
        </Button>
      </div>

      {/* Modal to report inappropriate recording */}
      <Modal show={formOpen} centered onHide={() => setFormOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Report Recording</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ReportForm onSubmit={onSubmit} />
        </Modal.Body>
      </Modal>
    </div>
  );
}

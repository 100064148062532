import { useEffect, useState } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import type { RootState, AppDispatch } from "./store";

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useAudio = () => {
  const [audio] = useState(new Audio());
  const [playing, setPlaying] = useState(false);

  const setAudioSrc = (url: string) => (audio.src = url);
  const getAudioSrc = () => audio.src;
  const toggleAudio = () => setPlaying(!playing);

  useEffect(() => {
    playing ? audio.play() : audio.pause();
  }, [playing, audio]);

  useEffect(() => {
    audio.addEventListener("ended", () => setPlaying(false));
    audio.addEventListener("canplay", () => setPlaying(true));
    return () => {
      audio.removeEventListener("ended", () => setPlaying(false));
      audio.removeEventListener("canplay", () => setPlaying(false));
    };
  }, [audio]);

  return { playing, toggleAudio, setAudioSrc, getAudioSrc };
};

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
};

import { PayloadAction } from "@reduxjs/toolkit";
import { getContext } from "phonograph";
import { AudioSrcID, NewAudioSrc, SeekPayload, Track } from "./types";

const SILENCE_MP3 = require("./silence.mp3");
const FIRST_PLAY_DELAY_MS = 25;
/*
	A middleware that intercepts `audioEngine` redux store actions
	and applies the necessary operations to the audio resources.
*/
export const audioEngineMiddleware = function (store: any) {
  // Create container of audio resources that will be used
  // to support playback. This portion is only executed once
  // when the middleware is bootstrapped up with the redux store.
  const tracks = new Map<AudioSrcID, Track>();
  const silenceAudio = new Audio(SILENCE_MP3);
  let audioHasPlayed = false;
  return function (next: any) {
    return function (action: PayloadAction<any>) {
      console.debug(`AudioEngineMiddleware: ${action.type}`);

      // Technically, user interaction is required before an
      // AudioContext is permitted to be created, requiring us
      // to resume, if it's not already running.

      if (getContext().state !== "running") {
        getContext().resume();
      }

      switch (action.type) {
        case "audioEngine/upsertSource":
          const src = (action as PayloadAction<NewAudioSrc>).payload;
          if (!tracks.has(src.id)) {
            let track = new Track(src.id, src.url, store);
            tracks.set(src.id, track);
          }
          break;

        case "audioEngine/play":
          // Resets all tracks then play
          tracks.forEach((track) => {
            if (track.playing) {
              track.pause();
            }
          });
          const track = tracks.get((action as PayloadAction<AudioSrcID>).payload);
          // For iOS to play sound when in silent mode, first we must play
          // sound from an <audio> element to trick the OS into playing sound
          // from Web Audio API. So the first time we play a recording, we
          // sneak in 0.25 second of silence, then call the actual play method.
          // There are issues trying to play the recording audio immediately,
          // so we put FIRST_PLAY_DELAY_MS milliseconds delayed, before the
          // first audio play occurance. Without this, the audio playback
          // slider doesn't work on first recording play.
          if (!audioHasPlayed) {
            silenceAudio.play();
            audioHasPlayed = true;
            window.setTimeout(() => track?.play(), FIRST_PLAY_DELAY_MS);
          } else {
            track?.play();
          }
          break;

        case "audioEngine/pause":
          tracks.get((action as PayloadAction<AudioSrcID>).payload)?.pause();
          break;

        case "audioEngine/reset":
          tracks.get((action as PayloadAction<AudioSrcID>).payload)?.reset();
          break;

        case "audioEngine/seek":
          const { id, second } = (action as PayloadAction<SeekPayload>).payload;
          tracks.get(id)?.seek(second);
          break;

        default:
      }

      next(action);
    };
  };
};

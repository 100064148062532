import { FormEvent, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { InappropriateReason } from "../Data/API";

export interface Props {
  onSubmit: (reasons: string[]) => void;
}

export function ReportForm(props: Props) {
  const { onSubmit } = props;

  const [reasons, setReasons] = useState<string[]>([]);

  const onFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit(reasons);
    setReasons([]);
  };

  const onChange = (option: string, checked: boolean) => {
    if (checked && !reasons.includes(option)) {
      reasons.push(option);
    } else if (!checked && reasons.includes(option)) {
      setReasons(reasons.filter((x) => x !== option));
    }
  };

  return (
    <>
      <p>Select one or more reasons why this recording is inappropriate:</p>
      <Form onSubmit={onFormSubmit}>
        {Object.values(InappropriateReason).map((opt) => (
          <Row key={opt}>
            <Col>
              <Form.Check
                type="checkbox"
                inline
                onChange={(e) => onChange(opt, e.target.checked)}
                label={opt}
              />
            </Col>
          </Row>
        ))}
        <hr />
        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
    </>
  );
}

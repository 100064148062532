import {
  createEntityAdapter,
  EntityState,
  createSlice,
  PayloadAction,
  createSelector,
} from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { Recording, RecordingDetail } from "./types";
import { fetchRecordingAsync, fetchRecordingsAsync } from "./API";

const recordingAdapter = createEntityAdapter<Recording>({
  selectId: (rec) => rec.id,
  sortComparer: (a, b) => b.id - a.id,
});

export interface RecordingsState {
  data: EntityState<Recording | RecordingDetail>;
  status: "idle" | "loading" | "failed";
  count: number;
  searchResultIds: number[];
  searchQuery: string;
}

const initialState: RecordingsState = {
  data: recordingAdapter.getInitialState(),
  status: "idle",
  count: 0,
  searchResultIds: [],
  searchQuery: "",
};

export const recordingsSlice = createSlice({
  name: "data/recordings",
  initialState,
  reducers: {
    setSearchQuery: (state, action: PayloadAction<string>) => {
      state.searchQuery = action.payload;
      state.searchResultIds = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRecordingsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchRecordingsAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.count = action.payload.count;
        if (state.searchQuery !== "") {
          action.payload.results.forEach((r) => state.searchResultIds.push(r.id));
        }
        recordingAdapter.setMany(state.data, action.payload.results);
      })
      .addCase(fetchRecordingAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchRecordingAsync.fulfilled, (state, action) => {
        state.status = "idle";
        recordingAdapter.setOne(state.data, action.payload);
      });
  },
});

// Actions

export const { setSearchQuery } = recordingsSlice.actions;

// Selectors

export const recordingSelectors = recordingAdapter.getSelectors(
  (state: RootState) => state.recordings.data
);

export const selectRecordingStatus = (state: RootState) => state.recordings.status;

export const selectRecordingServerCount = (state: RootState) => state.recordings.count;

export const selectSearchQuery = (state: RootState) => state.recordings.searchQuery;

export const selectSearchResultIds = (state: RootState) => state.recordings.searchResultIds;

export const selectRecordingById = (id: number) => (state: RootState) =>
  recordingSelectors.selectById(state, id) as RecordingDetail;

export const selectVisibleRecording = createSelector(
  recordingSelectors.selectAll,
  selectSearchResultIds,
  selectSearchQuery,
  (recordings, searchResultIds, searchQuery) => {
    if (searchQuery !== "") {
      return recordings.filter((r) => searchResultIds.includes(r.id));
    }
    return recordings;
  }
);

export const selectPromptVolumeRecordings = (promptId: number) => (state: RootState) =>
  recordingSelectors.selectAll(state).filter(x => x.promptId === promptId);

// Export the reducer

export default recordingsSlice.reducer;

import { useEffect } from "react";
import FlipNumbers from "react-flip-numbers";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { fetchLinesAsync, fetchStatsAsync } from "../Data/API";
import { WelcomeModal } from "./WelcomeModal";
import { selectListens } from "../Data/statsSlice";
import { selectLines } from "../Data/linesSlice";
import { LineListItem } from "./LineListItem";

export function Header() {
  const dispatch = useAppDispatch();
  const lines = useAppSelector(selectLines);
  const listens = useAppSelector(selectListens);

  useEffect(() => {
    dispatch(fetchLinesAsync());
    dispatch(fetchStatsAsync());

    const interval = setInterval(() => {
      dispatch(fetchStatsAsync());
    }, 5000);

    return () => clearInterval(interval);
  }, [dispatch]);

  return (
    <>
      <h1 className="home-page-title">Post-Secret Voicemail</h1>
      <div>
        <span>The world is listening.</span>
        <p>What do you have to say...</p>
      </div>
      {lines
        .filter((x) => !x.isMain)
        .map((line) => (
          <LineListItem key={line.id} line={line} />
        ))}
      <a
        href="https://www.reddit.com/r/PostSecretVoicemail/"
        target="_blank"
        rel="noreferrer"
      >
        r/PostSecretVoicemail
      </a>
      <br/>
      <br/>
      <WelcomeModal />

      <FlipNumbers
        play
        color="#408800"
        background="#000"
        width={45}
        height={45}
        numbers={`${listens}`}
        duration={1}
      />
      <p className="counter-label">Listens from Around the World</p>
      <p>Click a cassette to toggle playback.</p>
    </>
  );
}

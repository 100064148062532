import InfiniteScroll from "react-infinite-scroll-component";
import { Col, Row, Spinner } from "react-bootstrap";
import { useAppSelector } from "../../app/hooks";
import { RecordingListItem } from "./RecordingListItem";
import { Recording } from "../Data/types";
import ScrollButton from "./ScrollButton";
import { selectRecordingStatus } from "../Data/recordingsSlice";

export interface Props {
  // Recordings to populate the list with
  recordings: Recording[];

  // Total number of recordings to expect. Not necessarily
  // the currently length of `recordings`
  total: number;

  // Function called to load more data
  loadNextPage: () => void;

  hidePromptLink: boolean;
}

export function ScrollableRecordingList(props: Props) {
  const { recordings, total, loadNextPage, hidePromptLink = false } = props;
  const status = useAppSelector(selectRecordingStatus);

  return (
    <div data-testid="RecordingList">
      <InfiniteScroll
        dataLength={recordings.length}
        next={loadNextPage}
        hasMore={recordings.length < total}
        loader={<></>}
      >
        {recordings.map((recording) => (
          <Row key={recording.id}>
            <Col>
              <RecordingListItem recording={recording} hidePromptLink={hidePromptLink} />
            </Col>
          </Row>
        ))}
      </InfiniteScroll>
      <ScrollButton />
      {status === "loading" && <Spinner className="mt-3 mb-5" animation="border" />}
    </div>
  );
}

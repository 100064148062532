import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import { store } from "./app/store";
import { HomePage } from "./features/Home";
import { PromptVolumePage } from "./features/Volumes/PromptVolumePage";
import { RecordingDetails } from "./features/Recordings/RecordingDetails";
import AudioControls from "./features/AudioEngine/AudioControls";
import "./App.css";

Sentry.init({
  dsn: "https://4a1491c73a09e917236b678eda44e6a8@o4507722348756992.ingest.us.sentry.io/4507722367172608",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/(www|staging).postsecretvoicemail\.com\/api/],
  // Session Replay
  // This sets the sample rate at 10%. You may want to change it to 100%
  // while in development and then sample at a lower rate in production.
  replaysSessionSampleRate: 0.01,
  // If you're not already sampling the entire session, change the sample
  // rate to 100% when sampling sessions where errors occur.
  replaysOnErrorSampleRate: 1.0,
});

interface RedirectProps {
  to: string;
}

function Redirect({ to }: RedirectProps) {
  let navigate = useNavigate();
  useEffect(() => {
    navigate(to);
  });
  return null;
}

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <div data-testid="App" className="App">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/m/:recordingId" element={<RecordingDetails />} />
            <Route path="/p/:promptId" element={<PromptVolumePage />} />
            <Route path="*" element={<Redirect to="/" />} />
          </Routes>
          <AudioControls />
        </div>
      </BrowserRouter>
    </Provider>
  );
}

export default App;

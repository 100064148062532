import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import audioEngineSlice from "../features/AudioEngine/audioEngineSlice";
import recordingsReducer from "../features/Data/recordingsSlice";
import linesReducer from "../features/Data/linesSlice";
import statsReducer from "../features/Data/statsSlice";
import promptsReducer from "../features/Data/promptsSlice";
import { audioEngineMiddleware } from "../features/AudioEngine/middleware";

export const store = configureStore({
  reducer: {
    recordings: recordingsReducer,
    lines: linesReducer,
    prompts: promptsReducer,
    stats: statsReducer,
    audioEngine: audioEngineSlice,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(audioEngineMiddleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

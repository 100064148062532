import { ChangeEvent, FormEvent, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { trackSearch } from "../../app/tracking";
import "./Home.css";

export interface Props {
  onSearch: (newQuery: string) => void;
  onClear: () => void;
}

export function Search(props: Props) {
  const { onSearch, onClear } = props;

  const [query, setQuery] = useState("");

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSearch(query);
    trackSearch(query);
  };

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
  };

  const clearSearch = () => {
    setQuery("");
    onClear();
  };

  return (
    <Form onSubmit={onSubmit} className="search-wrapper">
      <Form.Group>
        <Form.Control
          value={query}
          onChange={onChange}
          type="text"
          placeholder="Word, phrase, msg # ..."
        />
      </Form.Group>
      <Form.Group>
        <Button className="search-btn" variant="primary" type="submit">
          Search
        </Button>
      </Form.Group>
      <Form.Group>
        {query !== "" && (
          <Button
            className="search-btn"
            variant="secondary"
            onClick={clearSearch}
            type="button"
          >
            Clear
          </Button>
        )}
      </Form.Group>
    </Form>
  );
}

import { Line } from "../Data/types";

export interface Props {
  line: Line;
}

export function LineListItem(props: Props) {
  const { line } = props;
  return (
    <p>
      {line.name}: <a href={"tel:" + line.phoneNumber}>{line.phoneNumber}</a>
    </p>
  );
}

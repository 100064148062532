import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  play,
  pause,
  seek,
  selectActiveId,
  selectCurrentSecond,
  selectDurationSeconds,
  selectIsPlaying,
} from "./audioEngineSlice";
import { FaPlay, FaPause } from "react-icons/fa";

import "./AudioControls.css";
import moment from "moment";
import { Container } from "react-bootstrap";

const SLIDER_RESOLUTION = 50;

function formatMMSS(second: number) {
  return moment(second * 1000).format("m:ss");
}

function AudioControls() {
  const dispatch = useAppDispatch();
  const recordingIdActive = useAppSelector(selectActiveId);
  const isActive = recordingIdActive !== null;
  const currentSecond = useAppSelector(selectCurrentSecond(recordingIdActive ?? -1));
  const duration = useAppSelector(selectDurationSeconds(recordingIdActive ?? -1));
  const isPlaying = useAppSelector(selectIsPlaying(recordingIdActive ?? -1));

  const onSliderClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (recordingIdActive) {
      const tick = Number(e.target.value);
      dispatch(seek({ id: recordingIdActive, second: tick / SLIDER_RESOLUTION }));
    }
  };
  const onPlay = () => dispatch(play(recordingIdActive ?? -1));
  const onPause = () => dispatch(pause(recordingIdActive ?? -1));

  const activeClass = isActive ? "audio-controls-active" : "";

  return (
    <>
      <div className={`audio-controls-footer ${activeClass}`}>
        <Container className="audio-controls-content">
          <div>#{recordingIdActive}</div>
          <div className="audio-control-slider">
            <div>{formatMMSS(currentSecond + 1)}</div>
            <input
              type="range"
              value={currentSecond * SLIDER_RESOLUTION}
              step="1"
              min="0"
              max={duration * SLIDER_RESOLUTION}
              className="progress-slider"
              onChange={onSliderClick}
            />
            <div>{formatMMSS(duration)}</div>
          </div>
          {isPlaying && <FaPause className="play-pause-button" onClick={onPause} />}
          {!isPlaying && <FaPlay className="play-pause-button" onClick={onPlay} />}
        </Container>
      </div>
    </>
  );
}

export default AudioControls;

import { createSlice, EntityState, createEntityAdapter } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { fetchPromptsAsync, fetchPromptAsync } from "./API";
import { Prompt, PromptDetail } from "./types";

const promptAdapter = createEntityAdapter<Prompt>({
    selectId: (x) => x.id,
    sortComparer: (a, b) => b.id - a.id,
  });

export interface PromptsState {
  data: EntityState<Prompt | PromptDetail>;
  status: "idle" | "loading" | "failed";
}

const initialState: PromptsState = {
  data: promptAdapter.getInitialState(),
  status: "idle",
};

export const promptsSlice = createSlice({
  name: "data/prompts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPromptsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchPromptsAsync.fulfilled, (state, action) => {
        state.status = "idle";
        promptAdapter.setMany(state.data, action.payload.results);
      })
      .addCase(fetchPromptAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchPromptAsync.fulfilled, (state, action) => {
        state.status = "idle";
        promptAdapter.setOne(state.data, action.payload);
      });
  },
});

// Actions

// export const {} = dataSlice.actions;

// Selectors

export const promptSelectors = promptAdapter.getSelectors(
    (state: RootState) => state.prompts.data
  );

export const selectPrompts = (state: RootState) => state.prompts.data;

export const selectPromptById = (id: number) => (state: RootState) =>
    promptSelectors.selectById(state, id) as PromptDetail


// Export the reducer

export default promptsSlice.reducer;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import {
  AudioSrc,
  AudioSrcID,
  NewAudioSrc,
  SetCurrentSecondPayload,
  SeekPayload,
} from "./types";

export interface AudioEngineState {
  sources: AudioSrc[];
  active: AudioSrc | null;
}

const initialState: AudioEngineState = {
  sources: [],
  active: null,
};

export const audioEngineSlice = createSlice({
  name: "audioEngine",
  initialState,
  reducers: {
    upsertSource: (state, action: PayloadAction<NewAudioSrc>) => {
      const src = state.sources.find((x) => x.id === action.payload.id);
      if (!src) {
        state.sources.push({ ...action.payload, status: "idle", currentSecond: 0 });
      }
    },
    loading: (state, action: PayloadAction<AudioSrcID>) => {
      const src = state.sources.find((x) => x.id === action.payload);
      if (src) {
        src.status = "loading";
      }
    },
    loaded: (state, action: PayloadAction<AudioSrcID>) => {
      const src = state.sources.find((x) => x.id === action.payload);
      if (src && src.status === "loading") {
        src.status = "idle";
      }
    },
    play: (state, action: PayloadAction<AudioSrcID>) => {
      state.sources.forEach((src) => (src.status = "paused"));
      const src = state.sources.find((x) => x.id === action.payload);
      if (src) {
        src.status = "playing";
        state.active = src;
      }
    },
    pause: (state, action: PayloadAction<AudioSrcID>) => {
      const src = state.sources.find((x) => x.id === action.payload);
      if (src && src.status === "playing") {
        src.status = "paused";
      }
    },
    reset: (state, action: PayloadAction<AudioSrcID>) => {
      const src = state.sources.find((x) => x.id === action.payload);
      if (src && src.status !== "idle") {
        src.status = "idle";
      }
    },
    resetAll: (state) => {
      state.sources.forEach((src) => {
        src.status = "idle";
      });
    },
    seek: (state, action: PayloadAction<SeekPayload>) => {
      // Nothing to do to state, passes thru to audio middleware
    },
    updateSecond: (state, action: PayloadAction<SetCurrentSecondPayload>) => {
      const src = state.sources.find((x) => x.id === action.payload.id);
      if (src) {
        src.currentSecond = action.payload.second;
      }
    },
  },
});

// Actions

export const {
  upsertSource,
  loading,
  loaded,
  play,
  pause,
  reset,
  resetAll,
  seek,
  updateSecond,
} = audioEngineSlice.actions;

// Selectors

export const selectIsPlaying = (recordingId: number) => {
  return (state: RootState) =>
    state.audioEngine.sources.find((x) => x.id === recordingId)?.status === "playing";
};

export const selectIsLoading = (recordingId: number) => {
  return (state: RootState) =>
    state.audioEngine.sources.find((x) => x.id === recordingId)?.status === "loading";
};

export const selectActiveId = (state: RootState) => {
  return state.audioEngine.active ? (state.audioEngine.active.id as number) : null;
};

export const selectDurationSeconds = (recordingId: number) => {
  return (state: RootState) => {
    return state.audioEngine.sources.find((x) => x.id === recordingId)?.seconds ?? 0;
  };
};

export const selectCurrentSecond = (recordingId: number) => {
  return (state: RootState) => {
    return state.audioEngine.sources.find((x) => x.id === recordingId)?.currentSecond ?? 0;
  };
};

// Export the reducer

export default audioEngineSlice.reducer;

import ReactGA from "react-ga4";
import { UaEventOptions } from "react-ga4/types/ga4";
import { postPlayCounterIncrement } from "../features/Data/API";

const gaEnabled = window.location.hostname === "www.postsecretvoicemail.com";
const gaPropertyId = "G-2Q085S3EL6";

export function initializeTracking() {
  if (gaEnabled) {
    ReactGA.initialize(gaPropertyId);
  }
}

export function trackEventGA(options: UaEventOptions) {
  if (gaEnabled) {
    ReactGA.event(options);
  }
}

export function trackPlay(recordingId: number) {
  trackEventGA({
    category: "usage",
    action: "play",
    label: "User engaged audio playback",
    value: recordingId,
  });
  postPlayCounterIncrement(recordingId).then().catch();
}

export function trackShare(recordingId: number) {
  trackEventGA({
    category: "usage",
    action: "share",
    label: "User copied share link to clipboard",
    value: recordingId,
  });
}

export function trackSearch(searchText: string) {
  trackEventGA({
    category: "usage",
    action: "search",
    label: searchText,
  });
}

export function trackDonate(scope: string) {
  trackEventGA({
    category: "usage",
    action: "donate",
    label: `User clicked the donated button [scope: ${scope}]`,
  });
}

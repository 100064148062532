export function getCookie(name: string) {
  var cookieValue = null;
  if (document.cookie && document.cookie !== "") {
    var cookies = document.cookie.split(";");
    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i].trim();
      if (cookie.substring(0, name.length + 1) === name + "=") {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

export function dB(volume: number) {
  return 20 * Math.log10(volume);
}

export function round(value: number, places: number) {
  const factor = Math.pow(10, Math.round(places));
  return Math.round(factor * value) / factor;
}

import { useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { fetchRecordingAsync } from "../Data/API";
import { selectRecordingById, selectRecordingStatus } from "../Data/recordingsSlice";
import { RecordingListItem } from "./RecordingListItem";
import "./RecordingDetails.css";

export function RecordingDetails() {
  let params = useParams();
  const dispatch = useAppDispatch();

  const recordingId = Number(params.recordingId);
  const recording = useAppSelector(selectRecordingById(recordingId));
  const status = useAppSelector(selectRecordingStatus);

  useEffect(() => {
    dispatch(fetchRecordingAsync(recordingId));
  }, [dispatch, recordingId]);

  if (!recording) {
    return <></>;
  }

  return (
    <>
      <Helmet>
        <title>{`Post Secret Voicemail #${recordingId}`}</title>
        <meta name="description" content={recording.description} />
      </Helmet>
      <Container className="recording-details-container">
        <Link to="/">{"<-"} Back to Home</Link>
        <Row key={recording.id}>
          <Col>
            <RecordingListItem recording={recording} hidePromptLink={false} />
            {status === "loading" && <Spinner className="mt-3 mb-5" animation="border" />}
          </Col>
        </Row>
        <Row className="recording-transcript-row">
          <Col>
            <h3>Transcript</h3>
            <p>{recording.transcription}</p>
          </Col>
        </Row>
      </Container>
    </>
  );
}

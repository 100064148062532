import { useEffect, useState, createRef } from "react";
import Typewriter from "typewriter-effect";
import { useAppSelector, useWindowDimensions } from "../../app/hooks";
import { Container } from "react-bootstrap";
import { FaChevronDown } from "react-icons/fa";
import { selectMainLine } from "../Data/linesSlice";
import { LineListItem } from "./LineListItem";

export function MainPrompt() {
  const { height } = useWindowDimensions();
  const [initialCompleted, setInitialCompleted] = useState(false);
  const ref = createRef<HTMLDivElement>();
  const [opacity, setOpacity] = useState(1);
  const mainLine = useAppSelector(selectMainLine);

  useEffect(() => {
    // Changes opacity to 0 as user scrolls down
    const didScrollPage = () => setOpacity(Math.max(1 - window.scrollY / height, 0));
    window.addEventListener("scroll", didScrollPage);
    return () => {
      window.removeEventListener("keydown", didScrollPage);
    };
  }, [height]);

  const scrollToTop = () => {
    window.scrollTo({
      top: height,
      behavior: "smooth",
    });
  };

  return (
    <div ref={ref} className="main-prompt" style={{ height: height, opacity: opacity }}>
      {mainLine && (
        <Container className="main-prompt-container" style={{ height: height }}>
          <div></div>
          <div></div>
          <div style={{ minHeight: height / 4 }}>
            <Typewriter
              onInit={(typewriter) => {
                typewriter
                  .changeDelay(65)
                  .typeString(mainLine.prompt.text)
                  .callFunction(() => {
                    setInitialCompleted(true);
                  })
                  .pauseFor(4000)
                  .start();
              }}
              options={{
                wrapperClassName: "prompt-text",
                cursorClassName: "prompt-text",
                loop: true,
                deleteSpeed: 10,
              }}
            />
          </div>

          {!initialCompleted && <div className="main-line"></div>}
          {initialCompleted && (
            <div className="fade-in main-line">
              <LineListItem line={mainLine} />
            </div>
          )}

          {!initialCompleted && <div className="scroll-down-button"></div>}
          {initialCompleted && (
            <div className="scroll-down-button" onClick={scrollToTop}>
              <div>Listen</div>
              <FaChevronDown
                style={{
                  fontSize: 32,
                  fontWeight: "lighter",
                }}
              />
            </div>
          )}
        </Container>
      )}
    </div>
  );
}

import {
  IAnalyserNode,
  IAudioBuffer,
  IAudioBufferSourceNode,
  IAudioContext,
  IDynamicsCompressorNode,
  IGainNode,
} from "standardized-audio-context";
import { getContext } from "phonograph";
import { trackPlay } from "../../app/tracking";
import { startFreqVisAnimiation } from "./visualization";
import { reset, updateSecond, loading, loaded } from "./audioEngineSlice";
import { ADVANCED_LOGGING_ENABLED } from "../../features/AdvancedLogging";
import { dB, round } from "../../app/helpers";

let db: IDBDatabase;
const DB_NAME = "psvm";
const AUDIO_STORE = "audio";
const DB_VERSION = 1;

let openRequest = indexedDB.open(DB_NAME, DB_VERSION);

openRequest.onupgradeneeded = function (event: any) {
  console.warn(`Migrating IndexedDB. Name=${DB_NAME} NewVersion=${DB_VERSION}`);
  let db = event?.target?.result;
  db?.createObjectStore(AUDIO_STORE);
};

openRequest.onsuccess = function (event: any) {
  db = openRequest.result;
  console.debug("IndexedDB successfully opened", openRequest.result.name);
  // We clear the store so it doesn't continually fill users machine
  // Temporarily comment this out until we have better understanding of
  // failure cases coming from Sentry. Leave it to the user to clear storage
  // if they care too.
  // db.transaction(AUDIO_STORE, "readwrite").objectStore(AUDIO_STORE).clear();
  console.debug(`IndexedDB cleared ${AUDIO_STORE} store`);
};

openRequest.onerror = function (event) {
  // Error occurred while opening the database
  console.error(event);
};

export declare type AudioSrcID = number | string;
export interface NewAudioSrc {
  id: AudioSrcID;
  url: string;
  seconds: number;
}

export interface AudioSrc extends NewAudioSrc {
  status: "playing" | "paused" | "idle" | "loading";
  currentSecond: number;
}

export interface SetCurrentSecondPayload {
  id: AudioSrcID;
  second: number;
}

export interface SeekPayload {
  id: AudioSrcID;
  second: number;
}

// Abstraction of audio resources required to support
// audio playback, effects, visualizations, etc.
//
// NOTE: We currently use phonograph's Clip only for
// visualization needs, as it can properly decode MP3s
// such that `getByteTimeDomainData` works as expected
// in all browsers expect Firefox, and pipe actual
// `HTMLAudioElement` objects thru the WebAudio API to
// destination for actual sound.
export class Track {
  id: AudioSrcID;
  context: IAudioContext;
  analyser: IAnalyserNode<IAudioContext>;
  preamp: IGainNode<IAudioContext>;
  compressor: IDynamicsCompressorNode<IAudioContext>;
  animationFrameID: number;
  store: any;

  source: IAudioBufferSourceNode<IAudioContext> | null = null;
  startedAt = 0;
  pausedAt = 0;
  playing = false;
  intervalId: number;

  constructor(id: AudioSrcID, url: string, store: any) {
    this.id = id;
    this.context = getContext();
    this.store = store;
    this.analyser = this.context.createAnalyser();
    this.analyser.fftSize = 256;
    this.preamp = this.context.createGain();
    this.preamp.gain.value = 1.0;
    this.compressor = this.context.createDynamicsCompressor();
    this.compressor.attack.value = 0.03; // 30 ms
    this.compressor.threshold.value = -15; // dBs
    this.compressor.knee.value = 10;

    this.animationFrameID = -1;
    this.intervalId = -1;

    this.preamp
      .connect(this.compressor)
      .connect(this.analyser)
      .connect(this.context.destination);

    const check = db.transaction(AUDIO_STORE, "readonly").objectStore(AUDIO_STORE).get(this.id);

    check.onsuccess = () => {
      if (!check.result) {
        console.debug(`Fetching ${url}...`);
        this.store.dispatch(loading(this.id));

        fetch(url)
          .then((response) => response.arrayBuffer())
          .then((arrayBuffer) => {
            console.debug("Saving audio to IndexedDB...");

            const add = db
              .transaction(AUDIO_STORE, "readwrite")
              .objectStore(AUDIO_STORE)
              .put(arrayBuffer, this.id);

            add.onsuccess = () => {
              console.debug(`Saved`, add.result);
              this.store.dispatch(loaded(this.id));
            };

            add.onerror = (event) => {
              console.error(`Error saving to DB`, event);
              this.store.dispatch(loaded(this.id));
            };
          });
      }
    };

    check.onerror = (event) => {
      console.error(event);
    };
  }

  getCurrentTime() {
    if (this.pausedAt) {
      return this.pausedAt;
    }
    if (this.startedAt) {
      return this.context.currentTime - this.startedAt;
    }
    return 0;
  }

  play() {
    const query = db.transaction(AUDIO_STORE, "readonly").objectStore(AUDIO_STORE).get(this.id);

    query.onerror = (event) => {
      console.error(`Query error`, event);
    };

    query.onsuccess = () => {
      const source = this.context.createBufferSource();

      this.context.decodeAudioData(query.result).then((audioBuffer) => {
        console.debug(`Audio decoded: ${audioBuffer}`);
        this.configureEffects(audioBuffer);
        source.buffer = audioBuffer;
        source.connect(this.preamp);
        source.start(0, this.pausedAt);
        this.source = source;

        this.startedAt = this.context.currentTime - this.pausedAt;
        this.pausedAt = 0;
        this.playing = true;

        this.intervalId = window.setInterval(() => {
          if (ADVANCED_LOGGING_ENABLED) {
            console.log(
              "Compressor",
              this.id,
              "reduction:",
              round(this.compressor.reduction, 2)
            );
          }

          if (this.getCurrentTime() >= audioBuffer.duration - 0.05) {
            this.store.dispatch(reset(this.id));
            this.store.dispatch(updateSecond({ id: this.id, second: 0 }));
          } else {
            this.store.dispatch(updateSecond({ id: this.id, second: this.getCurrentTime() }));
          }
          this.store.dispatch(updateSecond({ id: this.id, second: this.getCurrentTime() }));
        }, 500);

        this.animationFrameID = startFreqVisAnimiation(this.id, this.analyser);
      });
    };

    trackPlay(this.id as number);
    return this;
  }

  pause() {
    const elapsed = this.context.currentTime - this.startedAt;
    this.reset();
    this.pausedAt = elapsed;
    return this;
  }

  reset() {
    if (this.source) {
      this.source.stop();
      this.source.disconnect();
      this.source = null;
    }
    this.pausedAt = 0;
    this.startedAt = 0;
    this.playing = false;

    window.clearInterval(this.intervalId);
    cancelAnimationFrame(this.animationFrameID);
    return this;
  }

  seek(second: number) {
    const wasPlaying = this.playing;
    this.reset();
    this.pausedAt = second;
    if (wasPlaying) {
      this.play();
    }
    this.store.dispatch(updateSecond({ id: this.id, second: second }));
    return this;
  }

  /*
  Reference Documentation:
  https://developer.mozilla.org/en-US/docs/Web/API/DynamicsCompressorNode
  https://dosits.org/science/advanced-topics/introduction-to-signal-levels/
  https://webaudioapi.com/book/Web_Audio_API_Boris_Smus_html/ch03.html
  */
  private configureEffects(audioBuffer: IAudioBuffer) {
    const start_t = new Date();

    // Compute the peak volume in [0, 1]
    const channelData = audioBuffer.getChannelData(0);
    const peakVolume = channelData.reduce((max, x) => Math.max(max, Math.abs(x)));

    // Add gain to the signal to take it to where the knee ends above the compressor threshold.
    const peakDecibels = dB(peakVolume);
    const peakTargetDecibels = this.compressor.threshold.value + this.compressor.knee.value / 2;
    this.preamp.gain.value = Math.pow(10, peakDecibels / peakTargetDecibels);

    const end_t = new Date();

    if (ADVANCED_LOGGING_ENABLED) {
      const elapsed = round((end_t.getTime() - start_t.getTime()) / 1000, 5);
      console.log("===============================");
      console.log("Track     ", this.id, "  peak VU:", round(peakVolume, 3));
      console.log("Track     ", this.id, "  peak dB:", round(peakDecibels, 3));
      console.log("Preamp    ", this.id, "     gain:", round(this.preamp.gain.value, 3));
      console.log("Compressor", this.id, "threshold:", this.compressor.threshold.value);
      console.log("Compressor", this.id, "     knee:", this.compressor.knee.value);
      console.log("Compressor", this.id, "    ratio:", this.compressor.ratio.value);
      console.log("Compressor", this.id, "   attack:", round(this.compressor.attack.value, 3));
      console.log("Compressor", this.id, "  release:", round(this.compressor.release.value, 3));
      console.log("Calculation time:", elapsed, "sec");
    }
  }
}

import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { fetchLinesAsync } from "./API";
import { Line } from "./types";

export interface LinesState {
  data: Line[];
  status: "idle" | "loading" | "failed";
}

const initialState: LinesState = {
  data: [],
  status: "idle",
};

export const dataSlice = createSlice({
  name: "data/lines",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLinesAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchLinesAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.data = action.payload;
      });
  },
});

// Actions

// export const {} = dataSlice.actions;

// Selectors

export const selectLines = (state: RootState) => state.lines.data;

export const selectMainLine = (state: RootState) => {
  const main = state.lines.data.filter((x) => x.isMain);
  if (main.length > 0) {
    return main[0];
  }
  return null;
};

// Export the reducer

export default dataSlice.reducer;

import { AudioSrcID } from "./types";
import { requestAnimationFrame } from "request-animation-frame-polyfill";
import { IAnalyserNode, IAudioContext } from "standardized-audio-context";

// Grabs the <canvas> in RecordingListItem for recordingId, uses the analyzer
// routed into the audio node graph on MediaElementSourceNode creation, then
// draws a line containing the frequencies of the audio.
export function startFreqVisAnimiation(
  recordingId: AudioSrcID,
  analyzer: IAnalyserNode<IAudioContext>
) {
  var canvas = document.getElementById(`freqviz-${recordingId}`) as HTMLCanvasElement | null;
  var canvasCtx = canvas?.getContext("2d");

  if (!canvas || !canvasCtx) {
    return -1;
  }

  const bufferLength = analyzer.frequencyBinCount;
  var dataArray = new Uint8Array(bufferLength);

  const draw = () => {
    if (!canvas || !canvasCtx) {
      return -1;
    }

    analyzer.getByteTimeDomainData(dataArray);

    // Canvas styles
    canvasCtx.clearRect(0, 0, canvas.width, canvas.height);
    canvasCtx.fillStyle = "transparent";
    canvasCtx.fillRect(0, 0, canvas.width, canvas.height);
    canvasCtx.lineWidth = 4;
    canvasCtx.strokeStyle = "white";

    // Draw the frequency lines from left to right
    const sliceWidth = (canvas.width * 1.0) / bufferLength;
    for (var i = 0; i < bufferLength; i++) {
      var x = i * sliceWidth;
      var v = dataArray[i] / 128.0; // 0-128 volumes
      var y = (v * canvas.height) / 2;

      if (i === 0) {
        canvasCtx.beginPath();
        canvasCtx.moveTo(0, canvas.height / 2);
      } else {
        canvasCtx.lineTo(x, y);
      }
    }

    canvasCtx.lineTo(canvas.width, canvas.height / 2);
    canvasCtx.stroke();

    return requestAnimationFrame(draw);
  };

  return draw();
}

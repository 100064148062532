import { useEffect, useState } from "react";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import {
  selectRecordingServerCount,
  selectSearchQuery,
  selectVisibleRecording,
  setSearchQuery,
} from "../Data/recordingsSlice";
import { fetchRecordingsAsync } from "../Data/API";
import { ScrollableRecordingList } from "../Recordings/ScrollableRecordingList";
import { Container } from "react-bootstrap";
import { Header } from "./Header";
import { Search } from "./Search";
import "./Home.css";
import { MainPrompt } from "./MainPrompt";

export function HomePage() {
  const dispatch = useAppDispatch();
  const recordings = useAppSelector(selectVisibleRecording);
  const serverCount = useAppSelector(selectRecordingServerCount);
  const searchQuery = useAppSelector(selectSearchQuery);

  const [page, setPage] = useState(1);

  useEffect(() => {
    dispatch(fetchRecordingsAsync({}));
  }, [dispatch]);

  const loadNextPage = () => {
    dispatch(fetchRecordingsAsync({ page: page + 1, pageSize: 5, search: searchQuery }));
    setPage(page + 1);
  };

  const onSearch = (newQuery: string) => {
    if (newQuery !== searchQuery) {
      dispatch(setSearchQuery(newQuery));
      dispatch(fetchRecordingsAsync({ page: 1, pageSize: 5, search: newQuery }));
      setPage(1);
    }
  };

  return (
    <>
      <MainPrompt />
      {/* <DonateBanner /> */}
      <Container className="home-page-container">
        <Header />
        <Search onSearch={onSearch} onClear={() => onSearch("")} />
        <ScrollableRecordingList
          recordings={recordings}
          total={serverCount}
          hidePromptLink={false}
          loadNextPage={loadNextPage}
        />
      </Container>
    </>
  );
}

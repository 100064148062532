import { useEffect, useState } from "react";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { Link, useParams } from "react-router-dom";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet";

import {selectPromptVolumeRecordings, selectRecordingServerCount} from "../Data/recordingsSlice";
import { fetchPromptAsync, fetchRecordingsAsync } from "../Data/API";
import { ScrollableRecordingList } from "../Recordings/ScrollableRecordingList";
import { selectPromptById } from "../Data/promptsSlice";

import "./Volumes.css";

export function PromptVolumePage() {
  let params = useParams();
  const dispatch = useAppDispatch();

  const promptId = Number(params.promptId);
  const prompt = useAppSelector(selectPromptById(promptId));
  const recordings = useAppSelector(selectPromptVolumeRecordings(promptId));
  const serverCount = useAppSelector(selectRecordingServerCount);
  // const searchQuery = useAppSelector(selectSearchQuery);

  const [page, setPage] = useState(1);

  useEffect(() => {
    dispatch(fetchPromptAsync(promptId));
    dispatch(fetchRecordingsAsync({prompt: promptId}));
  }, [dispatch, promptId]);

  const loadNextPage = () => {
    dispatch(fetchRecordingsAsync({ page: page + 1, pageSize: 5, prompt: promptId /* search: searchQuery */ }));
    setPage(page + 1);
  };

  // const onSearch = (newQuery: string) => {
  //   if (newQuery !== searchQuery) {
  //     dispatch(setSearchQuery(newQuery));
  //     dispatch(fetchRecordingsAsync({ page: 1, pageSize: 5, search: newQuery }));
  //     setPage(1);
  //   }
  // };

  return (
    <>
      <Helmet>
        <title>{`Post Secret Voicemail Volume | Prompt #${promptId}`}</title>
        <meta name="description" content="Prompt Responses" />
      </Helmet>
      <Container className="volume-details-container">
        <Link to="/">{"<-"} Back to Home</Link>
        <h1 className="volume-title">Volume for Prompt #{promptId}</h1>
        <h4 className="volume-description">{ prompt ? prompt.text : '' }</h4>
        <ScrollableRecordingList
          recordings={recordings}
          total={serverCount}
          hidePromptLink={true}
          loadNextPage={loadNextPage}
        />
      </Container>
    </>
  );
}
